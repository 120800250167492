
    @import '@red-digital/react-devkit/scss/foundation/util/util';
    @import '~/scss/theme.scss';
    @import '~/scss/carousel.scss';
  
.hero {
  height: 756px;

  @media only screen and (min-width: 768px) {
    position: relative;
    height: 724px;
  }
  @media only screen and (min-width: 1200px) {
    position: relative;
    height: 640px;
  }
}